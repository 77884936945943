<template>
  <span @mouseover="snackbar = true" @mouseleave="snackbar = false"
    ><v-icon small class="mr-1 mb-1">description</v-icon>
    <span class="sub">{{ currentStatus }}</span>

    <v-snackbar v-model="snackbar" max-width="400" color="grey" class="pa-3">
      <v-row dense justify="center" align="start" class="pa-0 ma-0">
        <v-col cols="12">
          <span class="subtext">Histórico</span>
          <hr />
        </v-col>
      </v-row>
      <v-row
        dense
        justify="center"
        align="start"
        class="pa-0 mt-3"
        v-for="status in technicalReportStatus"
        :key="status.id"
      >
        <v-col cols="12" class="pa-0 ma-0">
          <v-icon small class="mr-1">event</v-icon
          ><span class="subtext">
            {{ dmyh(status.created) }}
          </span>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0">
          <v-icon small class="mr-1">description</v-icon
          ><span class="subtext">
          {{ status.status }}
          </span>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0">
          <v-icon small class="mr-1">person</v-icon
          ><span class="subtext">
            {{ status.editorName }}
          </span>
        </v-col>
      </v-row>
    </v-snackbar>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { formatDate } from "@/utils/formatDate";

export default {
  name: "TechnicalReportStatus",

  props: {
    technicalReportId: {
      type: Number,
    },
  },

  data() {
    return {
      items: [],
      currentStatus: "Submetido",
      snackbar: false,
    };
  },

  created() {
    if (this.technicalReportId)
      this.getAsyncTechnicalReportStatus(this.technicalReportId);
  },

  computed: {
    ...mapState("technicalReports", ["technicalReportStatus"]),
  },

  methods: {
    ...mapActions("technicalReports", [
      "ActionGetTechnicalReportStatusByTechnicalReportId",
    ]),

    async getAsyncTechnicalReportStatus(id) {
      try {
        await this.ActionGetTechnicalReportStatusByTechnicalReportId(id);
      } catch (error) {
        console.error(error);
      }
    },

    ordenationStatuses(value) {
      let v = JSON.parse(JSON.stringify(value));
      return v.sort(function (a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });
    },

    dmyh(date) {
      let d = new Date(date);
      return formatDate.dmyh(d);
    },
  },

  watch: {
    technicalReportId(newId) {
      this.getAsyncTechnicalReportStatus(newId);
    },
    technicalReportStatus(newValue) {
      if (newValue[0])
        this.currentStatus = this.ordenationStatuses(newValue)[0].status;
    },
  },
};
</script>

<style>
.subtext {
  font-size: 13px;
  font-weight: bold;
  color: white;
}

.sub {
  font-size: 13px;
  font-weight: bold;
  color: grey;
}
</style>