<template>
  <v-row justify="start" align="center" dense class="mb-5">
    <v-col cols="12">
      <v-row justify="start" align="center" dense>
        <v-col cols="12" sm="12" md="3" lg="2" class="text-start">
          <h3 class="title">Relatório Técnico</h3>
        </v-col>
        <v-col cols="12" sm="12" md="9" lg="10" class="text-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                x-small
                v-show="isOwner"
                color="amber accent-4"
                @click="showReportEdit()"
                ><v-icon small>edit</v-icon></v-btn
              >
            </template>
            <span>Editar</span>
          </v-tooltip>
          <Delete
            class="ml-2"
            :technicalReport="technicalReportDetails"
            v-show="isOwner"
            v-on:removed="removed"
          ></Delete>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-card outlined class="px-3 py-2">
        <v-row justify="start" align="center" dense>
          <v-col cols="12" sm="12" md="12">
            <v-row dense justify="start" align="end">
              <v-col cols="4" class="text-start">
                <span class="sub"
                  ><v-icon small class="mr-1 mb-1">person</v-icon
                  >{{
                    technicalReportDetails.student === undefined
                      ? ""
                      : technicalReportDetails.student.name
                  }}</span
                >
              </v-col>
              <v-col cols="2" class="text-center">
                <Status
                  :technicalReportId="technicalReportDetails.id"
                  class="mt-0"
                ></Status>
              </v-col>
              <v-col cols="6" class="text-end">
                <span class="sub"
                  ><v-icon small class="mb-0">update</v-icon
                  >{{ dmyh(technicalReportDetails.updatedAt) }}</span
                >
                <span class="sub"
                  ><v-icon small class="ml-5 mb-0">today</v-icon
                  >{{ dmyh(technicalReportDetails.createdAt) }}</span
                >
              </v-col>
            </v-row>
            <hr class="sub" />
          </v-col>
          <v-col cols="12" sm="12" md="9">
            <strong>Título:</strong> {{ technicalReportDetails.title }}
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <strong>Código:</strong> {{ technicalReportDetails.code }}
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <strong>Nota:</strong> {{ technicalReportDetails.note }}
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <strong>Instituição:</strong>
            {{ technicalReportDetails.institution }}
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <strong>Autores:</strong> {{ technicalReportDetails.author }}
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <strong>Abstract:</strong> {{ technicalReportDetails.abstract_ }}
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <span><strong>relatório:</strong></span
            ><FileDownload
              :file="technicalReportDetails.reportFile"
              class="mb-3 ml-1"
            ></FileDownload>
            <span class="ml-5"><strong>bib:</strong></span
            ><FileDownload
              :file="technicalReportDetails.bibFile"
              class="mb-3 ml-1"
            ></FileDownload>
            <hr class="mt-10" />
          </v-col>
          <v-col cols="12" sm="12" md="6" class="text-start"
            ><div class="subtitle">
              Comentários
              <CommentInsert
                class="ml-2 mb-1"
                :technicalReportId="
                  technicalReportDetails ? technicalReportDetails.id : 0
                "
              ></CommentInsert></div
          ></v-col>
          <v-col cols="12" sm="12" md="6" class="text-end"
            ><div class="subtitle" v-show="isEditor">
              Status
              <StatusInsert
                class="ml-2 mb-1"
                :technicalReport="technicalReportDetails"
              ></StatusInsert>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="mt-2"
            v-for="comment in technicalReportComments"
            :key="comment.id"
          >
            <Comment :comment="comment"></Comment>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapActions } from "vuex";

import { formatDate } from "@/utils/formatDate";
import * as storage from "@/pages/auth/storage";

import FileDownload from "@/components/common/file/FileDownload.vue";
import Comment from "./TechnicalReportComment.vue";
import Status from "./TechnicalReportStatus.vue";
import CommentInsert from "./TechnicalReportCommentInset.vue";
import Delete from "./TechnicalReportDelete.vue";
import StatusInsert from "./TechnicalReportStatusInsert.vue";

export default {
  name: "TechnicalReportDetails",

  props: {
    id: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  components: {
    Status,
    Comment,
    Delete,
    StatusInsert,
    FileDownload,
    CommentInsert,
  },

  data() {
    return {
      user: {},
    };
  },
  created() {
    this.user = storage.getLocalData().user;
    if (this.id !== 0) this.getAsyncTechnicalReportDetails(this.id);
    else {
      const id_ = this.$route.params.id;
      this.getAsyncTechnicalReportDetails(id_);
    }
  },

  computed: {
    ...mapState("technicalReports", [
      "technicalReportDetails",
      "technicalReportStatus",
      "technicalReportComments",
    ]),

    getTechnicalReportStatus() {
      return this.technicalReportStatus;
    },

    getTechnicalReportComments() {
      return this.technicalReportComments;
    },

    isOwner() {
      if (this.technicalReportDetails.student == undefined) return false;

      return this.user.name == this.technicalReportDetails.student.name;
    },

    isEditor() {
      return this.user.roles.includes("editor");
    },
  },

  methods: {
    ...mapActions("technicalReports", [
      "ActionGetTechnicalReportDetails",
      "ActionGetTechnicalReportStatusByTechnicalReportId",
      "ActionGetTechnicalReportCommentsByTechnicalReportId",
    ]),
    async getAsyncTechnicalReportDetails(id) {
      try {
        await this.ActionGetTechnicalReportDetails(id);
      } catch (err) {
        console.log(err);
      }
    },

    async getAsyncTechnicalReportStatusByTechnicalReportId(id) {
      try {
        await this.ActionGetTechnicalReportStatusByTechnicalReportId(id);
      } catch (err) {
        console.error(err);
      }
    },

    async getAsyncTechnicalReportCommentsByTechnicalReportId(id) {
      try {
        await this.ActionGetTechnicalReportCommentsByTechnicalReportId(id);
      } catch (err) {
        console.error(err);
      }
    },

    dmyh(date) {
      return formatDate.dmyh(date);
    },

    showReportEdit() {
      this.$router.push({
        name: "TechnicalReportUpdate",
        params: { id: this.technicalReportDetails.id },
      });
    },

    removed() {
      this.$router.push({
        name: "TechnicalReports",
        params: { username: this.technicalReportDetails.student.academicCode },
      });
    },
  },

  watch: {
    technicalReportDetails(newValue) {
      this.getAsyncTechnicalReportStatusByTechnicalReportId(newValue.id);
      this.getAsyncTechnicalReportCommentsByTechnicalReportId(newValue.id);
    },
  },
};
</script>
<style scoped>
.sub {
  font-size: 13px;
  font-weight: bold;
  color: grey;
}
</style>