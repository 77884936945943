var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      on: {
        mouseover: function($event) {
          _vm.snackbar = true
        },
        mouseleave: function($event) {
          _vm.snackbar = false
        }
      }
    },
    [
      _c("v-icon", { staticClass: "mr-1 mb-1", attrs: { small: "" } }, [
        _vm._v("description")
      ]),
      _c("span", { staticClass: "sub" }, [_vm._v(_vm._s(_vm.currentStatus))]),
      _c(
        "v-snackbar",
        {
          staticClass: "pa-3",
          attrs: { "max-width": "400", color: "grey" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pa-0 ma-0",
              attrs: { dense: "", justify: "center", align: "start" }
            },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("span", { staticClass: "subtext" }, [_vm._v("Histórico")]),
                _c("hr")
              ])
            ],
            1
          ),
          _vm._l(_vm.technicalReportStatus, function(status) {
            return _c(
              "v-row",
              {
                key: status.id,
                staticClass: "pa-0 mt-3",
                attrs: { dense: "", justify: "center", align: "start" }
              },
              [
                _c(
                  "v-col",
                  { staticClass: "pa-0 ma-0", attrs: { cols: "12" } },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { small: "" } },
                      [_vm._v("event")]
                    ),
                    _c("span", { staticClass: "subtext" }, [
                      _vm._v(" " + _vm._s(_vm.dmyh(status.created)) + " ")
                    ])
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "pa-0 ma-0", attrs: { cols: "12" } },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { small: "" } },
                      [_vm._v("description")]
                    ),
                    _c("span", { staticClass: "subtext" }, [
                      _vm._v(" " + _vm._s(status.status) + " ")
                    ])
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "pa-0 ma-0", attrs: { cols: "12" } },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { small: "" } },
                      [_vm._v("person")]
                    ),
                    _c("span", { staticClass: "subtext" }, [
                      _vm._v(" " + _vm._s(status.editorName) + " ")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }