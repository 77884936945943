<template>
  <span class="body">
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          x-small
          outlined
          color="green"
          @click="dialog = true"
          ><v-icon small>add</v-icon></v-btn
        ></template
      >
      <span>Inserir novo comentário</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="900">
      <v-card class="">
        <v-card-title>
          <span class="">Inserir Novo Comentário</span>
        </v-card-title>
        <v-card-text>
          <v-row dense justify="start" align="start">
            <v-col
              cols="12"
              sm="12"
              md="6"
              class="text-center"
              v-show="isEditor"
            >
              <v-select
                dense
                outlined
                no-data-text="Sem opções"
                :items="statuses"
                v-model="status"
                return-object
                label="alterar status do relatório"
                :disabled="!checkbox"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="3" class="text-left" v-show="isEditor">
              <v-checkbox
                v-model="checkbox"
                label="Alterar status"
                color="green"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="text-center">
              {{ isPublicate }}
            </v-col>
            <v-col cols="12" sm="12" md="12" class="text-center">
              <v-textarea
                :disabled="progress"
                outlined
                :rules="rules"
                label="Comentário"
                counter="2000"
                v-model="textarea"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="">
          <v-row dense justify="center" align="center" class="mb-3">
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn :disabled="progress" outlined color="red" @click="close()"
                >Cancelar<v-icon>clear</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn
                :disabled="progress"
                outlined
                color="green"
                @click="postAsyncTechnicalReportComment()"
                >Inserir<v-icon>check</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" class="text-center" v-show="progress">
              <v-progress-linear
                indeterminate
                color="green"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      <v-row justify="center" align="center" class="pa-3">
        {{ snackbarMessage }}
      </v-row>
    </v-snackbar>
  </span>
</template>

<script>
import { mapActions } from "vuex";
import * as storage from "@/pages/auth/storage";

export default {
  name: "TechnicalReportCommentInsert",
  props: {
    technicalReportId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      user: {},
      status: null,
      statuses: [],
      dialog: false,
      textarea: "",
      color: null,
      snackbar: false,
      timeout: 5000,
      snackbarMessage: "",
      progress: false,
      checkbox: false,
      rules: [
        (t) => !!t || "O comentário não pode ser vazio!!",
        (t) =>
          !t || t.length <= 2000 || "O texto excedeu o limite de caracteres!",
      ],
    };
  },

  created() {
    this.user = storage.getLocalData().user;
    this.getStatuses();
  },

  computed: {
    isEditor() {
      return this.user.roles.includes("editor");
    },

    isPublicate() {
      if (this.status == "Publicado" && this.checkbox)
        return "Atenção! Essa operação irá publicar automaticamente o relatório técnico.";
      return "";
    },
  },

  methods: {
    ...mapActions("technicalReports", ["ActionPostTechnicalReportComment"]),
    ...mapActions("technicalReports", [
      "ActionGetPossibleStatuses",
      "ActionPostTechnicalReportStatus",
    ]),

    async postAsyncTechnicalReportComment() {
      try {
        this.progress = true;
        if (this.textarea.length > 0) {
          await this.ActionPostTechnicalReportComment({
            technicalReportId: this.technicalReportId,
            comment: this.textarea,
          });

          if (this.checkbox && this.status != null) {
            let payload = {
              technicalReportId: this.technicalReportId,
              status: this.status,
            };
            await this.ActionPostTechnicalReportStatus(payload);
          }

          this.color = null;
          this.snackbarMessage = "Comentário inserido com sucesso!";
          this.close();
        } else {
          this.color = "red";
          this.snackbarMessage = "O comentário não pode ser vazio!!!";
        }
      } catch (e) {
        this.color = "red";
        this.snackbarMessage = "Erro ao inserir comentário! " + e.bodyText;
        console.error(e);
      } finally {
        this.snackbar = true;
        this.progress = false;
      }
    },

    async getStatuses() {
      try {
        let res = await this.ActionGetPossibleStatuses();
        if (res.status == 200) {
          this.statuses = res.body;
        }
      } catch (error) {
        console.error(error);
      }
    },

    close() {
      this.textarea = new String();
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>