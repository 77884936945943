var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { dense: "", justify: "start", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "text-start", attrs: { cols: "12" } },
                [_vm._v(" " + _vm._s(_vm.comment.comment) + " ")]
              ),
              _c(
                "v-col",
                { staticClass: "text-start mb-0", attrs: { cols: "6" } },
                [_c("CommentDelete", { attrs: { comment: _vm.comment } })],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-end mb-0", attrs: { cols: "6" } },
                [
                  _c(
                    "span",
                    { staticClass: "sub mr-2" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1 mb-1", attrs: { small: "" } },
                        [_vm._v("person")]
                      ),
                      _vm._v(
                        _vm._s(_vm.btnDisabled ? "Eu" : _vm.comment.user.name)
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "sub" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1 mb-1", attrs: { small: "" } },
                        [_vm._v("today")]
                      ),
                      _vm._v(_vm._s(_vm.dmyh(_vm.comment.commentDate)))
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }