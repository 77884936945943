<template>
  <span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          x-small
          color="red"
          @click="dialog = true"
          ><v-icon small>delete</v-icon>
        </v-btn>
      </template>
      <span>Apagar</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="900">
      <v-card outlined>
        <v-card-title>
          <div class="title">Deletar Relatório?</div>
        </v-card-title>
        <v-card-text>
          <div>
            Deseja mesmo deletar o relatório
            <strong>{{ technicalReport.title }}</strong
            >? A operação não poderá ser desfeita e toda a informação referente
            ao presente relatório será removida do sistema!
          </div>
          <span class="obs"
            >Obs.: Relatórios publicados não podem ser excluídos!</span
          >
        </v-card-text>
        <v-card-actions>
          <v-row dense justify="center" align="center">
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn outlined color="red" @click="close()"
                >Cancelar<v-icon class="ml-2">clear</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn
                outlined
                color="green"
                @click="delAsyncTechnicalReport(technicalReport.id)"
                :disabled="progress"
                >Deletar<v-icon class="ml-2">delete</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" class="text-center mb-1">
              <v-progress-linear
                v-show="progress"
                indeterminate
                color="red"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      <v-row justify="center" align="center" class="pa-3">
        {{ snackbarMessage }}
      </v-row>
    </v-snackbar>
  </span>
</template>

<script>
import { mapActions } from "vuex";

export default {
  emits: ["removed"],
  name: "TechnicalReportDelete",
  props: {
    technicalReport: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      progress: false,
      color: null,
      snackbar: false,
      timeout: 5000,
      snackbarMessage: "",
    };
  },

  methods: {
    ...mapActions("technicalReports", ["ActionDelTechnicalReport"]),

    async delAsyncTechnicalReport(id) {
      try {
        this.progress = true;
        await this.ActionDelTechnicalReport(id);
        this.color = null;
        this.snackbarMessage = "Relatório removido com sucesso!";
        this.close();
      } catch (e) {
        console.error(e);
        this.color = "red";
        this.snackbarMessage = e.bodyText;
      } finally {
        this.snackbar = true;
        this.progress = false;
      }
    },

    close() {
      this.dialog = false;
      this.$emit('removed', true);
    },
  },
};
</script>

<style>
.obs {
  font-size: 13px;
  font-weight: bold;
  margin-top: 30px;
}
</style>