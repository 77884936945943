var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "mb-5",
      attrs: { justify: "start", align: "center", dense: "" }
    },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "start", align: "center", dense: "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-start",
                  attrs: { cols: "12", sm: "12", md: "3", lg: "2" }
                },
                [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("Relatório Técnico")
                  ])
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-end",
                  attrs: { cols: "12", sm: "12", md: "9", lg: "10" }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isOwner,
                                          expression: "isOwner"
                                        }
                                      ],
                                      attrs: {
                                        outlined: "",
                                        "x-small": "",
                                        color: "amber accent-4"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showReportEdit()
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("edit")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Editar")])]
                  ),
                  _c("Delete", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isOwner,
                        expression: "isOwner"
                      }
                    ],
                    staticClass: "ml-2",
                    attrs: { technicalReport: _vm.technicalReportDetails },
                    on: { removed: _vm.removed }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            { staticClass: "px-3 py-2", attrs: { outlined: "" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "start", align: "center", dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { dense: "", justify: "start", align: "end" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-start", attrs: { cols: "4" } },
                            [
                              _c(
                                "span",
                                { staticClass: "sub" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1 mb-1",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("person")]
                                  ),
                                  _vm._v(
                                    _vm._s(
                                      _vm.technicalReportDetails.student ===
                                        undefined
                                        ? ""
                                        : _vm.technicalReportDetails.student
                                            .name
                                    )
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "2" }
                            },
                            [
                              _c("Status", {
                                staticClass: "mt-0",
                                attrs: {
                                  technicalReportId:
                                    _vm.technicalReportDetails.id
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "text-end", attrs: { cols: "6" } },
                            [
                              _c(
                                "span",
                                { staticClass: "sub" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mb-0",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("update")]
                                  ),
                                  _vm._v(
                                    _vm._s(
                                      _vm.dmyh(
                                        _vm.technicalReportDetails.updatedAt
                                      )
                                    )
                                  )
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "sub" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-5 mb-0",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("today")]
                                  ),
                                  _vm._v(
                                    _vm._s(
                                      _vm.dmyh(
                                        _vm.technicalReportDetails.createdAt
                                      )
                                    )
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("hr", { staticClass: "sub" })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12", sm: "12", md: "9" } }, [
                    _c("strong", [_vm._v("Título:")]),
                    _vm._v(" " + _vm._s(_vm.technicalReportDetails.title) + " ")
                  ]),
                  _c("v-col", { attrs: { cols: "12", sm: "12", md: "3" } }, [
                    _c("strong", [_vm._v("Código:")]),
                    _vm._v(" " + _vm._s(_vm.technicalReportDetails.code) + " ")
                  ]),
                  _c("v-col", { attrs: { cols: "12", sm: "12", md: "6" } }, [
                    _c("strong", [_vm._v("Nota:")]),
                    _vm._v(" " + _vm._s(_vm.technicalReportDetails.note) + " ")
                  ]),
                  _c("v-col", { attrs: { cols: "12", sm: "12", md: "6" } }, [
                    _c("strong", [_vm._v("Instituição:")]),
                    _vm._v(
                      " " + _vm._s(_vm.technicalReportDetails.institution) + " "
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "12", sm: "12", md: "12" } }, [
                    _c("strong", [_vm._v("Autores:")]),
                    _vm._v(
                      " " + _vm._s(_vm.technicalReportDetails.author) + " "
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "12", sm: "12", md: "12" } }, [
                    _c("strong", [_vm._v("Abstract:")]),
                    _vm._v(
                      " " + _vm._s(_vm.technicalReportDetails.abstract_) + " "
                    )
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "12" } },
                    [
                      _c("span", [_c("strong", [_vm._v("relatório:")])]),
                      _c("FileDownload", {
                        staticClass: "mb-3 ml-1",
                        attrs: { file: _vm.technicalReportDetails.reportFile }
                      }),
                      _c("span", { staticClass: "ml-5" }, [
                        _c("strong", [_vm._v("bib:")])
                      ]),
                      _c("FileDownload", {
                        staticClass: "mb-3 ml-1",
                        attrs: { file: _vm.technicalReportDetails.bibFile }
                      }),
                      _c("hr", { staticClass: "mt-10" })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-start",
                      attrs: { cols: "12", sm: "12", md: "6" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "subtitle" },
                        [
                          _vm._v(" Comentários "),
                          _c("CommentInsert", {
                            staticClass: "ml-2 mb-1",
                            attrs: {
                              technicalReportId: _vm.technicalReportDetails
                                ? _vm.technicalReportDetails.id
                                : 0
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-end",
                      attrs: { cols: "12", sm: "12", md: "6" }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isEditor,
                              expression: "isEditor"
                            }
                          ],
                          staticClass: "subtitle"
                        },
                        [
                          _vm._v(" Status "),
                          _c("StatusInsert", {
                            staticClass: "ml-2 mb-1",
                            attrs: {
                              technicalReport: _vm.technicalReportDetails
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._l(_vm.technicalReportComments, function(comment) {
                    return _c(
                      "v-col",
                      {
                        key: comment.id,
                        staticClass: "mt-2",
                        attrs: { cols: "12", sm: "12", md: "12" }
                      },
                      [_c("Comment", { attrs: { comment: comment } })],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }