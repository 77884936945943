<template>
  <span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          x-small
          outlined
          color="amber"
          @click="dialog = true"
          ><v-icon small>edit</v-icon></v-btn
        ></template
      >
      <span>Alterar status do relatório</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="500">
      <v-card class="">
        <v-card-title>
          <span class="">Alterar status do relatório</span>
        </v-card-title>
        <v-card-text>
          <div>
          <v-select
            dense
            outlined
            no-data-text="Sem opções"
            :items="statuses"
            v-model="status"
            return-object
          ></v-select>
          </div>
          <div>
            {{ isPublicate }}
          </div>
        </v-card-text>
        <v-card-actions class="">
          <v-row dense justify="center" align="center" class="mb-3">
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn :disabled="progress" outlined color="red" @click="close()"
                >Cancelar<v-icon>clear</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn
                :disabled="progress"
                outlined
                color="green"
                @click="postAsyncTechnicalReportStatus()"
                >Inserir<v-icon>check</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" class="text-center" v-show="progress">
              <v-progress-linear
                indeterminate
                color="green"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      <v-row justify="center" align="center" class="pa-3">
        {{ snackbarMessage }}
      </v-row>
    </v-snackbar>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as storage from "@/pages/auth/storage";

export default {
  name: "TechnicalReportStatusInsert",

  props: {
    technicalReport: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      color: null,
      snackbar: false,
      timeout: 5000,
      snackbarMessage: "",
      progress: false,
      dialog: false,
      statuses: [],
      status,
    };
  },

  created() {
    this.user = storage.getLocalData().user;
    this.getStatuses();
  },

  computed: {
    ...mapState("technicalReports", ["technicalReportStatus"]),

    isPublicate(){
      if (this.status == 'Publicado')
        return 'Atenção! Essa operação irá publicar automaticamente o relatório técnico.'
      return ''  
    }
  },

  methods: {
    ...mapActions("technicalReports", [
      "ActionPostTechnicalReportStatus",
      "ActionGetPossibleStatuses",
    ]),

    async getStatuses() {
      try {
        let res = await this.ActionGetPossibleStatuses();
        if (res.status == 200) {
          this.statuses = res.body;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async postAsyncTechnicalReportStatus() {
      try {
        this.progress = true;
        let payload = {
          technicalReportId: this.technicalReport.id,
          status: this.status,
        };
        await this.ActionPostTechnicalReportStatus(payload);
        this.color = null;
        this.snackbarMessage = "Alteração de status realizada com sucesso!";
        this.close();
      } catch (error) {
        console.error(error);
        this.color = "red";
        this.snackbarMessage = "Erro ao alterar status! " + error.bodyText;
      } finally {
        this.progress = false;
        this.snackbar = true;
      }
    },

    close() {
      this.dialog = false;
      this.progress = false;
    },
  },
};
</script>

<style>
</style>