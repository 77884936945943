<template>
  <v-card outlined>
    <v-card-text>
      <v-row dense justify="start" align="center">
        <v-col cols="12" class="text-start">
          {{ comment.comment }}
        </v-col>
        <v-col cols="6" class="text-start mb-0">
          <CommentDelete :comment="comment"></CommentDelete>
        </v-col>
        <v-col cols="6" class="text-end mb-0">      
          <span class="sub mr-2"
            ><v-icon small class="mr-1 mb-1">person</v-icon
            >{{ btnDisabled ? "Eu" : comment.user.name }}</span
          >
          <span class="sub"
            ><v-icon small class="mr-1 mb-1">today</v-icon
            >{{ dmyh(comment.commentDate) }}</span
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatDate } from "@/utils/formatDate";
import * as storage from "@/pages/auth/storage";

import CommentDelete from "./TechnicalReportCommentDelete.vue";

export default {
  name: "TechnicalReportComment",
  props: { comment: { type: Object, required: true } },
  components: { CommentDelete },

  data() {
    return {
      dialog: false,
    };
  },

  create() {},

  computed: {
    btnDisabled() {
      return storage.getLocalData().user.name == this.comment.user.name;
    },
  },

  methods: {
    dmyh(date) {
      return formatDate.dmyh(date);
    },
  },
};
</script>

<style>
.sub {
  font-size: 13px;
  color: grey;
}
</style>