<template>
  <span>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }"
        ><v-btn
          v-bind="attrs"
          v-on="on"
          x-small
          text
          fab
          color="red"
          v-show="isOwner"
          @click="dialog = true"
          ><v-icon>delete</v-icon></v-btn
        ></template
      >
      <span>Apagar comentário?</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="400">
      <v-card class="pb-2">
        <v-card-title class="text-center">
          <span class="">Apagar</span>
        </v-card-title>
        <v-card-actions>
          <v-row dense justify="center" align="center">
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn
                :disabled="progress"
                outlined
                color="red"
                @click="dialog = false"
                >Cancelar<v-icon class="ml-2">clear</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <v-btn
                :disabled="progress"
                outlined
                color="green"
                @click="deleteAsyncTechnicalReportComment(comment.id)"
                >Apagar<v-icon class="ml-2">delete</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      <v-row justify="center" align="center" class="pa-3">
        {{ snackbarMessage }}
      </v-row>
    </v-snackbar>
  </span>
</template>

<script>
import { mapActions } from "vuex";

import * as storage from "@/pages/auth/storage";

export default {
  name: "TechnicalReportCommnetDelete",
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      progress: false,
      user: {},
      color: null,
      snackbar: false,
      timeout: 5000,
      snackbarMessage: "",
    };
  },

  created() {
    this.user = storage.getLocalData().user;
  },

  computed: {
    isOwner() {
      return this.user.name == this.comment.user.name;
    },
  },

  methods: {
    ...mapActions("technicalReports", ["ActionDeleteTechnicalReportComment"]),

    async deleteAsyncTechnicalReportComment(id) {
      try {
        this.progress = true;
        await this.ActionDeleteTechnicalReportComment(id);
        this.dialog = false;
        this.color = null;
        this.snackbarMessage = "Comentário removido com sucesso!";
        this.close();
        this.$destroy();
      } catch (e) {
        console.error(e);
        this.color = "red";
        this.snackbarMessage = e.bodyText;
      } finally {
        this.snackbar = true;
        this.progress = false;
      }
    },
  },
};
</script>

<style>
</style>