var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { outlined: "", "x-small": "", color: "red" },
                          on: {
                            click: function($event) {
                              _vm.dialog = true
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "" } }, [_vm._v("delete")])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Apagar")])]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "900" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c("v-card-title", [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Deletar Relatório?")
                ])
              ]),
              _c("v-card-text", [
                _c("div", [
                  _vm._v(" Deseja mesmo deletar o relatório "),
                  _c("strong", [_vm._v(_vm._s(_vm.technicalReport.title))]),
                  _vm._v(
                    "? A operação não poderá ser desfeita e toda a informação referente ao presente relatório será removida do sistema! "
                  )
                ]),
                _c("span", { staticClass: "obs" }, [
                  _vm._v("Obs.: Relatórios publicados não podem ser excluídos!")
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    {
                      attrs: { dense: "", justify: "center", align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "12", md: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "red" },
                              on: {
                                click: function($event) {
                                  return _vm.close()
                                }
                              }
                            },
                            [
                              _vm._v("Cancelar"),
                              _c("v-icon", { staticClass: "ml-2" }, [
                                _vm._v("clear")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "12", md: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                color: "green",
                                disabled: _vm.progress
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delAsyncTechnicalReport(
                                    _vm.technicalReport.id
                                  )
                                }
                              }
                            },
                            [
                              _vm._v("Deletar"),
                              _c("v-icon", { staticClass: "ml-2" }, [
                                _vm._v("delete")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center mb-1",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-progress-linear", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.progress,
                                expression: "progress"
                              }
                            ],
                            attrs: { indeterminate: "", color: "red" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout, color: _vm.color },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pa-3",
              attrs: { justify: "center", align: "center" }
            },
            [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }