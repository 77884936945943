var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            "x-small": "",
                            outlined: "",
                            color: "amber"
                          },
                          on: {
                            click: function($event) {
                              _vm.dialog = true
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", { attrs: { small: "" } }, [_vm._v("edit")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Alterar status do relatório")])]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            {},
            [
              _c("v-card-title", [
                _c("span", {}, [_vm._v("Alterar status do relatório")])
              ]),
              _c("v-card-text", [
                _c(
                  "div",
                  [
                    _c("v-select", {
                      attrs: {
                        dense: "",
                        outlined: "",
                        "no-data-text": "Sem opções",
                        items: _vm.statuses,
                        "return-object": ""
                      },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    })
                  ],
                  1
                ),
                _c("div", [_vm._v(" " + _vm._s(_vm.isPublicate) + " ")])
              ]),
              _c(
                "v-card-actions",
                {},
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-3",
                      attrs: { dense: "", justify: "center", align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "12", md: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.progress,
                                outlined: "",
                                color: "red"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.close()
                                }
                              }
                            },
                            [
                              _vm._v("Cancelar"),
                              _c("v-icon", [_vm._v("clear")])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "12", md: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.progress,
                                outlined: "",
                                color: "green"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.postAsyncTechnicalReportStatus()
                                }
                              }
                            },
                            [
                              _vm._v("Inserir"),
                              _c("v-icon", [_vm._v("check")])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.progress,
                              expression: "progress"
                            }
                          ],
                          staticClass: "text-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: "", color: "green" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout, color: _vm.color },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pa-3",
              attrs: { justify: "center", align: "center" }
            },
            [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }